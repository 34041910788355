import axios from "axios";

const API_BASE_URL = "https://lcm-ai-api-dev.fpt-aic.com";
const AUTH_TOKEN = "eeb71539-c7f8-4215-8274-746a987d4d65";

const api = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
		authToken: AUTH_TOKEN,
	},
});

export const convertToPdf = async (file: File): Promise<File> => {
	const formData = new FormData();
	formData.append("file", file);

	try {
		const response = await api.post("/convert-to-pdf", formData, {
			responseType: "blob",
			headers: {
				"Content-Type": "multipart/form-data",
				authToken: AUTH_TOKEN,
			},
		});

		const pdfBlob = new Blob([response.data], { type: "application/pdf" });
		return new File([pdfBlob], file.name.replace(/\.[^/.]+$/, ".pdf"), {
			type: "application/pdf",
		});
	} catch (error) {
		console.error("Error converting file to PDF:", error);
		throw error;
	}
};

export const inferenceApi = async (
	entity: string,
	purpose: string,
	ndaType: string,
	ndaFile: File,
) => {
	const formData = new FormData();
	formData.append("entity", entity);
	formData.append("purpose", purpose);
	formData.append("nda_type", ndaType);
	formData.append("nda_bytes", ndaFile);

	try {
		const response = await api.post("/inference", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				authToken: AUTH_TOKEN,
			},
			responseType: "json",
		});
		return response.data;
	} catch (error) {
		console.error("Error in inference API:", error);
		throw error;
	}
};

export const downloadFile = async (downloadLink: string): Promise<string> => {
	try {
		const response = await api.get(downloadLink, {
			responseType: "blob",
			headers: {
				authToken: AUTH_TOKEN,
			},
		});
		const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
		return fileUrl;
	} catch (error) {
		console.error("Error in downloading file:", error);
		throw error;
	}
};

export const fetchDocumentByToken = async (token: string) => {
	try {
		const response = await api.get(`/view/?token=${token}`, {
			responseType: "json",
			headers: {
				authToken: AUTH_TOKEN,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching document data by token:", error);
		throw error;
	}
};

export const transferEditedFile = async (
	issueKey: string,
	fileData: string,
	fileName: string,
): Promise<any> => {
	const formData = new FormData();
	formData.append("issueKey", issueKey);
	formData.append("fileData", fileData);
	formData.append("fileName", fileName);

	try {
		const response = await api.post("/transfer-edited-file/", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
				authToken: AUTH_TOKEN,
			},
			timeout: 120000,
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error) && error.code === "ECONNABORTED") {
			console.error("Request timed out:", error);
		} else if (axios.isAxiosError(error) && error.code === "ERR_NETWORK") {
			console.error("Error network:", error);
		} else {
			console.error("Error in transferring edited file:", error);
		}
		throw error;
	}
};
